import { Journal } from "../model/journal";
import { ActionData } from "../model/action-data";
import { Status } from "../model/status";
import { FileUpload } from "../model/file-upload";
import { Person } from "../model/person";
import * as uuid from "uuid";
import { Policy } from "../model/policy";
import { Driver } from "../model/driver";

export class BusinessTransactionHelper {
    public static typeId = {
        customer: {
            from: "200",
            to: "299"
        },
        productBundle: {
            from: "500",
            to: "599"
        },
        cancellationRange:{
            from:"591",
            to:"595"
        },
        productBundleRange: {
            from: "500",
            to: "549"
        },
        pendingMatter: {
            from: "600",
            to: "699"
        },
        documents: {
            from: "700",
            to: "799"
        },
        provision: {
            from: "1100",
            to: "1199"
        },
        provisionAccounting: {
            from: "1200",
            to: "1299"
        },
        provisionPendingMatter: {
            from: "1300",
            to: "1399"
        },
        message: {
            from: "2000",
            to: "2099"
        },
        offer: {
            from: "3100",
            to: "3199"
        },
        offerFillDocuments: {
            from: "3200",
            to: "3249"
        },
        filledDocuments: {
            from: "3250",
            to: "3299"
        },
        offerCreate: {
            from: "3300",
            to: "3399"
        },
        offerCreateGMTable: {
            from: "3190",
            to: "3199"
        },
        offerCreated: {
            from: "3400",
            to: "3499"
        },
        offerPolicy:{
            from: "561",
            to: "562"
        },
        leadDetailsStatus: {
            from: "4101",
            to: "4101"
        },
        terminDetailsStatus: {
            from: "4201",
            to: "4201"
        },
        assignLeadToAgent: {
            from: "4202",
            to: "4202"
        },
        leadDetailsUpdate: {
            from: "4207",
            to: "4207"
        },
        newCreateLead: {
            steuerkunde: "4241",
            eigentermin: "4242",
            adressvermittler: "4243",
            weiterempfehlung: "4244"
        },
        createLead: {
            from: "4206",
            to: "4206"
        },
        
        productLeadLink: {
            from: "4501",
            to: "4501"
        },
        leadNegative: {
            from: "4502",
            to: "4502"
        },
        leadReminders: {
            from: "4102",
            to: "4104"
        },
        leadStatus: {
            from: "4110",
            to: "4116"
        },
        leadEventStatus: {
            from: "4117",
            to: "4117"
        },
        terminStatus: {
            from: "4210",
            to: "4213"
        },
        terminStatusDialog:{
            from: "4210",
            to: "4211"
        },
        terminReminders: {
            from: "4204",
            to: "4205"
        },
        neMortgage:{
            from: "4300",
            to: "4300"
        },
        mortgage:{
            from: "4301",
            to: "4301"
        },
        tasks: {
            from: "4500",
            to: "4500"
        },
        completeTask: {
            from: "4503",
            to: "4503"
        },
        vCard: {
            from: "2500",
            to: "2500"
        }
    };

    public static createVCardAction(quantity: string, ordererName: string, dateNow: string): ActionData {

        const journal = new Journal();

        journal.typeId = BusinessTransactionHelper.typeId.vCard.from;
        journal.transactionName = "VC Request";
        journal.inputChannel = "myneo";
        journal.ordererName = ordererName;
        journal.creationDate = dateNow
        journal.transactionId = uuid.v4()
        journal.sorKeys = {
          quantity: quantity
        }

        return new ActionData(journal);
    }

    public static createQuotation(): ActionData {
        const journal = new Journal();
        journal.transactionId = uuid.v4();
        journal.inputChannel = "Makler Portal";
        journal.transactionName = "Offerten anfordern";
        journal.typeId = BusinessTransactionHelper.typeId.offerFillDocuments.from;
        return new ActionData(journal);
    }

    public static createOffer(uploadFiles: FileUpload[], selectedPerson: Person, typeID, branch?, companies?): ActionData {
        const journal = new Journal();
        journal.transactionId = uuid.v4();
        journal.reasonForChange = "GM-Neue Offerte";
        const status = new Status();
        status.name = "Erstellt";
        status.completed = false;
        status.source = "Maklerportal";
        journal.status = status;
        journal.inputChannel = "Makler Portal";
        journal.transactionName = "Antrag Offerte";
        journal.typeId = typeID;
        journal.typeName = "Antrag Offerte";
        if (branch && companies) {
            journal.sorKeys = { [branch]: companies.join(",") };
        } else if (branch) {
            journal.sorKeys = { [branch]: "" };
        }
        if (selectedPerson != null) {
            return new ActionData(journal, uploadFiles, selectedPerson);
        }
        return new ActionData(journal, uploadFiles);
    }

    public static createOfferWithData(uploadFiles: FileUpload[], selectedPerson: Person, typeID, branch?, companies?, data?): ActionData {
        const journal = new Journal();
        journal.transactionId = uuid.v4();
        journal.uuid = journal.transactionId;
        journal.reasonForChange = "GM-Neue Offerte";
        const status = new Status();
        status.name = "Erstellt";
        status.completed = false;
        status.source = "Maklerportal";
        journal.status = status;
        journal.inputChannel = "Makler Portal";
        journal.transactionName = "Antrag Offerte";
        journal.typeId = typeID;
        journal.typeName = "Antrag Offerte";
        if (branch && companies) {
            journal.sorKeys = { [branch]: companies.join(", ") };
        } else if (branch) {
            journal.sorKeys = { [branch]: "" };
        }

        if (data) {
            for (const key of Object.keys(data)) {
                // Exclude document from journal offer_data
                if (key === "document") {
                    continue;
                }

                journal.offer_data[key] = data[key];
            }
        }
        if (selectedPerson != null) {
            return new ActionData(journal, uploadFiles, selectedPerson);
        }
        return new ActionData(journal, uploadFiles);
    }

    public static createOfferWithDataPolicy(uploadFiles: FileUpload[], selectedPerson: Driver, typeID, branch?, companies?, data?): ActionData {
        const journal = new Journal();
        journal.transactionId = uuid.v4();
        journal.uuid = journal.transactionId;
        journal.reasonForChange = "GM-Neue Offerte";
        const status = new Status();
        status.name = "Erstellt";
        status.completed = false;
        status.source = "Maklerportal";
        journal.status = status;
        journal.inputChannel = "Makler Portal";
        journal.transactionName = "Antrag Offerte";
        journal.typeId = typeID;
        journal.typeName = "Antrag Offerte";
        if (branch && companies) {
            journal.sorKeys = { [branch]: companies.join(",") };
        } else if (branch) {
            journal.sorKeys = { [branch]: "" };
        }

        if (data) {
            journal.sorKeys.channel = 'WEB';
            for (const key of Object.keys(data)) {
                // Exclude document from journal sorKeys
                if (key === "document") {
                    continue;
                }
                
                journal.sorKeys[key] = data[key];
            }
        }
        if (selectedPerson != null) {
            return new ActionData(journal, uploadFiles, null, null, selectedPerson);
        }
        return new ActionData(journal, uploadFiles);
    }

    public static createActionObject(
        srcTransactionName: string,
        srcTransactionTypeId: string,
        srcTransactionTypeName: string,
        reasonForChange: string,
        descriptionField: string,
        operationalIdExternal: string,
        uploadFiles: FileUpload[],
        selectedPerson: Person,
        policy: Policy,
        groupId?: string,
        completed?: boolean,
    ): ActionData {

        const journal = new Journal();
        journal.transactionId = uuid.v4();
        journal.reasonForChange = reasonForChange;
        journal.descriptionField = descriptionField;
        journal.transactionName = srcTransactionName;
        journal.typeId = srcTransactionTypeId;
        journal.typeName = srcTransactionTypeName;
        journal.operationIdExternal = operationalIdExternal;
        journal.references.push({
            id: operationalIdExternal ? operationalIdExternal : "-1",
            type: {
                key: "060",
                sdaValue: "Geschäftsvorgang",
                sorValue: "Geschäftsvorgang"
            } as any
        });
        journal.sorKeys = {
            "ProcessID": "" + journal.transactionId,
            "groupId": groupId
        };
        journal.inputChannel = 'myneo';
        journal.status.completed = completed ?? journal.status.completed;
        journal.status.name = journal.status.completed ? 'Abgeschlossen' : journal.status.name;
        return new ActionData(journal, uploadFiles, selectedPerson, policy);
    }

    public static createLeadBTActionObject(
        srcTransactionName: string,
        srcTransactionTypeId: string,
        srcTransactionTypeName: string,
        reasonForChange: string,
        descriptionField: string,
        operationalIdExternal: string,
        uploadFiles: FileUpload[],
        selectedPerson: Person,
        effectiveDate: string,
        sorKeys: { [key: string]: string } = {},
        ordererName?: string
    ): ActionData {
        const journal = new Journal();
        journal.transactionId = uuid.v4();
        journal.reasonForChange = reasonForChange;
        journal.effectiveDate = effectiveDate;
        journal.descriptionField = descriptionField;
        journal.transactionName = srcTransactionName;
        journal.typeId = srcTransactionTypeId;
        journal.typeName = srcTransactionTypeName;
        journal.operationIdExternal = operationalIdExternal;
        journal.ordererName = ordererName || "";
        if (sorKeys) {
            for (const key of Object.keys(sorKeys)) {
                journal.sorKeys[key] = sorKeys[key];
            }
        }
        journal.inputChannel = 'myneo';
        return new ActionData(journal, uploadFiles, selectedPerson, null);
    }
}
