import { Routes } from "@angular/router";
import { TestPageComponent } from "./modules/test/pages/test-page.component";
import { MyCustomersPageComponent } from "./modules/customers/pages/my-customers-page.component";
import { MyCustomersDetailPageComponent } from "./modules/customers/pages/my-customers-detail-page.component";
import { MyOpenIssuesPageComponent } from "./modules/openissues/pages/my-open-issues-page.component";
import { PolicyDetailPageComponent } from "./modules/customers/pages/policy-detail-page.component";
import { MyOfferPageComponent } from "./modules/offers/pages/my-offer-page.component";
import { MyCommissionPageComponent } from "./modules/commission/pages/my-commission-page.component";
import { MessagesPageComponent } from "./modules/messages/pages/messages-page.component";
import { ApplicationsPageComponent } from "./modules/applications/pages/applications-page.component";
import { SearchResultPageComponent } from "./modules/search/pages/search-result-page.component";
import { DevelopModeGuard } from "./modules/core/guards/DevelopModeGuard";
import { InfoComponent } from "./modules/info/components/info.component";
import { ReferralsComponent } from "./modules/referrals/components/referrals.component";
import { LeadsNavbarComponent } from "./modules/leads/pages/leads/leads-navbar.component";
import { LeadsEditComponent } from "./modules/leads/pages/leads/leads-edit.component";
import { LeadsEditTerminComponent } from "./modules/leads/pages/leads/leads-edit-termin.component";
import { PendingChangesGuard } from "./modules/core/guards/pending-changes.guard";
import { LeadsManagementComponent } from "./modules/leads/pages/lead-management/leads-management/leads-management.component";
import { AuthGuard } from "./modules/core/guards/AuthGuard";
import { CreateLeadComponent } from "./modules/leads/pages/create-lead/create-lead.component";
import { EventsComponent } from "./modules/events/pages/events.component";
import { TerminManagementComponent } from "./modules/leads/pages/dialogs/termin-management/termin-management.component";
import { ClientGuard } from "./modules/core/guards/ClientGuard";
import { SupplierGuard } from "./modules/core/guards/SupplierGuard";
import { LeadPoolComponent } from "./modules/leads/pages/lead-pool/lead-pool.component";
import { AgentPageComponent } from "./modules/livechat/support/agent-page/agent-page.component";
import { CancellationManagementComponent } from './modules/applications/cancellation-management/cancellation-management.component';
import { MortgageComponent } from './modules/mortgage/mortgage/mortgage.component';
import { LeadpoolGuard } from "./modules/modals/guards/leadpool.guard";
import { MyOfferPageOldComponent } from "./modules/offers-old/pages/my-offer-page-old.component";
import { AdminGuard } from "./modules/core/guards/AdminGuard";
import { LiveChatGuard } from "./modules/core/guards/LiveChatGuard";

export const ROUTES: Routes = [
    { path: "", redirectTo: "messages", pathMatch: "full" },
    { path: "test", component: TestPageComponent, canActivate: [DevelopModeGuard] }
];
//NOSONAR
export const sucheColor = "--color-suche";
export const nachrichtenColor = "--color-nachrichten";
export const leadsColor = "--color-leads";
export const mycostumerColor = "--color-mycostumer";
export const applicationsColor = "--color-applications";
export const offerColor = "--color-offer";
export const journalColor = "--color-journal";
export const myComisionColor = "--color-myComision";
export const infoColor = "--color-info";
export const referralColor = "--color-referral";
export const eventsColor = "--color-events";
export const leadManagementColor = "--color-leadManagement";
export const terminManagementColor = "--color-terminManagement";
export const mortgageColor = "--color-mortgage";


export const ROUTING_DATA = [
    {
        name: "SearchResult",
        path: "result",
        component: SearchResultPageComponent,
        isInMenu: true,
        isActive: false,
        color: sucheColor,
        hasBreadcrumb: false,
        menuLabel: "MENU.ENTRIES.SEARCH",
        title:
            [
                {
                    lang: "de",
                    value: "Suchergebnis"
                },
                {
                    lang: "en",
                    value: "Results"
                },
                {
                    lang: "fr",
                    value: "Résultats"
                },
                {
                    lang: "it",
                    value: "Risultati"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Ergebnis der Portalsuche"
            },
            {
                lang: "en",
                value: "Results of the portal search"
            },
            {
                lang: "fr",
                value: "Résultat de la recherche du portail"
            },
            {
                lang: "it",
                value: "Risultati della ricerca nel portale"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Messages",
        path: "messages",
        component: MessagesPageComponent,
        isInMenu: true,
        isActive: true,
        hasBreadcrumb: false,
        color: nachrichtenColor,
        menuLabel: "MENU.ENTRIES.MESSAGES",
        title:
            [
                {
                    lang: "de",
                    value: "Nachrichten"
                },
                {
                    lang: "en",
                    value: "Messages"
                },
                {
                    lang: "fr",
                    value: "Messages"
                },
                {
                    lang: "it",
                    value: "Messaggi"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Nachrichten Webseite"
            },
            {
                lang: "en",
                value: "Messages Webpage"
            },
            {
                lang: "fr",
                value: "Page Web des messages"
            },
            {
                lang: "it",
                value: "Pagina Web dei messaggi"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Leads",
        path: "leads",
        component: LeadsNavbarComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: leadsColor,
        menuLabel: "MENU.ENTRIES.LEADS",
        canActivate: [ClientGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Leads"
                },
                {
                    lang: "en",
                    value: "Leads"
                },
                {
                    lang: "fr",
                    value: "Leads"
                },
                {
                    lang: "it",
                    value: "Leads"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Leads Webseite"
            },
            {
                lang: "en",
                value: "Leads Webpage"
            },
            {
                lang: "fr",
                value: "Page Web des Leads"
            },
            {
                lang: "it",
                value: "Pagina Web dei Leads"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "LeadsEdit",
        path: "lead/:id/edit",
        component: LeadsEditComponent,
        hasBreadcrumb: false,
        color: nachrichtenColor,
        menuLabel: "MENU.ENTRIES.LEADS",
        canActivate: [ClientGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Lead Detail"
                },
                {
                    lang: "en",
                    value: "Lead Detail"
                },
                {
                    lang: "fr",
                    value: "Lead Détail"
                },
                {
                    lang: "it",
                    value: "Lead Dettaglio"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Leads Webseite"
            },
            {
                lang: "en",
                value: "Leads Webpage"
            },
            {
                lang: "fr",
                value: "Page Web des Leads"
            },
            {
                lang: "it",
                value: "Pagina Web dei Leads"
            }
        ],
        routerParameter: null,
        routerAnchors: null,
        canDeactivate: [PendingChangesGuard]
    },
    {
        name: "TerminEdit",
        path: "termine/:id/edit",
        component: LeadsEditTerminComponent,
        hasBreadcrumb: false,
        color: nachrichtenColor,
        canActivate: [ClientGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Termin Detail"
                },
                {
                    lang: "en",
                    value: "Appiontment Detail"
                },
                {
                    lang: "fr",
                    value: "Détail du Rendez-vous"
                },
                {
                    lang: "it",
                    value: "Dettaglio dell'appuntamento"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Termin Webseite"
            },
            {
                lang: "en",
                value: "Appointment Website"
            },
            {
                lang: "fr",
                value: "Site de Rendez-vous"
            },
            {
                lang: "it",
                value: "Sito di Appuntamenti"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "CreateLead",
        path: "create-lead",
        component: CreateLeadComponent,
        hasBreadcrumb: false,
        color: nachrichtenColor,
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Lead Erstellen"
                },
                {
                    lang: "en",
                    value: "Create Lead"
                },
                {
                    lang: "fr",
                    value: "Créer des Lead"
                },
                {
                    lang: "it",
                    value: "Crea Lead"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Lead Erstellen"
            },
            {
                lang: "en",
                value: "Create Lead"
            },
            {
                lang: "fr",
                value: "Créer des Lead"
            },
            {
                lang: "it",
                value: "Crea Lead"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "MyCustomers",
        path: "mycustomers",
        component: MyCustomersPageComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: true,
        color: mycostumerColor,
        menuLabel: "MENU.ENTRIES.MYCUSTOMER",
        canActivate: [ClientGuard, SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Meine Kunden"
            },
            {
                lang: "en",
                value: "My Customer"
            },
            {
                lang: "fr",
                value: "Mon Client"
            },
            {
                lang: "it",
                value: "Il mio cliente"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Kunden"
            },
            {
                lang: "en",
                value: "Test_en Kunden"
            },
            {
                lang: "fr",
                value: "Test_fr Kunden"
            },
            {
                lang: "it",
                value: "Test_it Kunden"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    }, {
        name: "MyCustomersDetail",
        path: "mycustomers/detail",
        component: MyCustomersDetailPageComponent,
        isInMenu: false,
        isActive: false,
        canActivate: [ClientGuard, SupplierGuard],
        hasBreadcrumb: true,
        title: [
            {
                lang: "de",
                value: "Kunden Detail"
            },
            {
                lang: "en",
                value: "Customer Detail"
            },
            {
                lang: "fr",
                value: "Détail Client"
            },
            {
                lang: "it",
                value: "Dettaglio cliente"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Meine Kunden Detail"
            },
            {
                lang: "en",
                value: "Test_en Meine Kunden Detail"
            },
            {
                lang: "fr",
                value: "Test_fr Meine Kunden Detail"
            },
            {
                lang: "it",
                value: "Test_it Meine Kunden Detail"
            }
        ],
        routerParameter: ["representativeuuid", "selecteduuid"],
        routerAnchors: null
    }, {
        name: "MyCustomersPolicyDetail",
        path: "mycustomers/detail/:representativeuuid/policy",
        component: PolicyDetailPageComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: true,
        canActivate: [ClientGuard, SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Meine Kunden"
            },
            {
                lang: "en",
                value: "My Customers"
            },
            {
                lang: "fr",
                value: "Mes Clients"
            },
            {
                lang: "it",
                value: "I miei clienti"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Meine Kunden Policen Detail"
            },
            {
                lang: "en",
                value: "My Customers Policy Detail"
            },
            {
                lang: "fr",
                value: "Détails de la politique de mes clients"
            },
            {
                lang: "it",
                value: "Dettagli della politica dei miei clienti"
            }
        ],
        routerParameter: ["policyid"],
        routerAnchors: null,
    },
    {
        name: "MyCustomersPolicyDetail",
        path: "mycustomers/detail/:representativeuuid/:selecteduuid/policy",
        component: PolicyDetailPageComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: true,
        canActivate: [ClientGuard, SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Meine Kunden"
            },
            {
                lang: "en",
                value: "My Customers"
            },
            {
                lang: "fr",
                value: "Mes clients"
            },
            {
                lang: "it",
                value: "I miei clienti"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Meine Kunden Policen Detail"
            },
            {
                lang: "en",
                value: "My Customers Policy Detail"
            },
            {
                lang: "fr",
                value: "Détails de la politique de mes clients"
            },
            {
                lang: "it",
                value: "Dettagli della politica dei miei clienti"
            }
        ],
        routerParameter: ["policyid", "representativeuuid", "selecteduuid"],
        routerAnchors: null,
    },
    {
        name: "MyData",
        path: "mydata/detail",
        component: MyCustomersDetailPageComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: true,
        color: mycostumerColor,
        menuLabel: "MENU.ENTRIES.MYDATA",
        canActivate: [SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Meine Daten"
            },
            {
                lang: "en",
                value: "My Data"
            },
            {
                lang: "fr",
                value: "Mes données"
            },
            {
                lang: "it",
                value: "I miei dati"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Meine Kunden Detail"
            },
            {
                lang: "en",
                value: "Test_en Meine Kunden Detail"
            },
            {
                lang: "fr",
                value: "Test_fr Meine Kunden Detail"
            },
            {
                lang: "it",
                value: "Test_it Meine Kunden Detail"
            }
        ],
        routerParameter: ["representativeuuid", "selecteduuid"],
        routerAnchors: null
    },
    {
        name: "MyDataPolicyDetail",
        path: "mydata/detail/:representativeuuid/policy",
        component: PolicyDetailPageComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: true,
        canActivate: [SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Meine Daten"
            },
            {
                lang: "en",
                value: "My Data"
            },
            {
                lang: "fr",
                value: "Mes données"
            },
            {
                lang: "it",
                value: "I miei dati"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Meine Daten Policen Detail"
            },
            {
                lang: "en",
                value: "My Data Policy Detail"
            },
            {
                lang: "fr",
                value: "Détails de la politique de mes données"
            },
            {
                lang: "it",
                value: "Dettagli della politica dei miei dati"
            }
        ],
        routerParameter: ["policyid"],
        routerAnchors: null,
    },
    {
        name: "MyDataPolicyDetail",
        path: "mydata/detail/:representativeuuid/:selecteduuid/policy",
        component: PolicyDetailPageComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: true,
        canActivate: [SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Meine Daten"
            },
            {
                lang: "en",
                value: "My Data"
            },
            {
                lang: "fr",
                value: "Mes clients"
            },
            {
                lang: "it",
                value: "I miei clienti"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test Meine Daten Policen Detail"
            },
            {
                lang: "en",
                value: "My Customers Policy Detail"
            },
            {
                lang: "fr",
                value: "Détails de la politique de mes clients"
            },
            {
                lang: "it",
                value: "Dettagli della politica dei miei dati"
            }
        ],
        routerParameter: ["policyid", "representativeuuid", "selecteduuid"],
        routerAnchors: null,
    },
    {
        name: "Applications",
        path: "applications",
        component: ApplicationsPageComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: applicationsColor,
        menuLabel: "MENU.ENTRIES.APPLICATIONS",
        canActivate: [SupplierGuard],
        title: [
            {
                lang: "de",
                value: "Stand Anträge"
            },
            {
                lang: "en",
                value: "Applications"
            },
            {
                lang: "fr",
                value: "Applications"
            },
            {
                lang: "it",
                value: "Applicazioni"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Stand der aktuellen Anträge"
            },
            {
                lang: "en",
                value: "Customer applications"
            },
            {
                lang: "fr",
                value: "Applications client"
            },
            {
                lang: "it",
                value: "Applicazioni dei clienti"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Offers",
        path: "offers",
        component: MyOfferPageOldComponent,
        isInMenu: true,
        isActive: false,
        color: offerColor,
        hasBreadcrumb: false,
        menuLabel: "MENU.ENTRIES.OFFER",
        canActivate: [SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Offerten"
                },
                {
                    lang: "en",
                    value: "Offers"
                },
                {
                    lang: "fr",
                    value: "Des offres"
                },
                {
                    lang: "it",
                    value: "Offerte"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Offerten Webseite"
            },
            {
                lang: "en",
                value: "Offer Webpage"
            },
            {
                lang: "fr",
                value: "Page Web de l'offre"
            },
            {
                lang: "it",
                value: "Pagina web dell'offerta"
            }
        ],
        routerParameter: ["representativeuuid", "selecteduuid"],
        routerAnchors: null
    },
    {
        name: "Neo Offers",
        path: "neo-offers",
        component: MyOfferPageComponent,
        isInMenu: false,
        isActive: false,
        color: offerColor,
        hasBreadcrumb: false,
        menuLabel: "MENU.ENTRIES.NEO-OFFER",
        canActivate: [AdminGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Neo Offerten"
                },
                {
                    lang: "en",
                    value: "Neo Offers"
                },
                {
                    lang: "fr",
                    value: "Des Neo offres"
                },
                {
                    lang: "it",
                    value: "Neo Offerte"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Offerten Webseite"
            },
            {
                lang: "en",
                value: "Offer Webpage"
            },
            {
                lang: "fr",
                value: "Page Web de l'offre"
            },
            {
                lang: "it",
                value: "Pagina web dell'offerta"
            }
        ],
        routerParameter: ["representativeuuid", "selecteduuid"],
        routerAnchors: null
    },
    {
        name: "MyJournals",
        path: "myjournals",
        component: MyOpenIssuesPageComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: journalColor,
        menuLabel: "MENU.ENTRIES.JOURNAL",
        canActivate: [SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Pendenzen"
                },
                {
                    lang: "en",
                    value: "open issues"
                },
                {
                    lang: "fr",
                    value: "questions ouvertes"
                },
                {
                    lang: "it",
                    value: "questioni aperte"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Pendenzen Webseite"
            },
            {
                lang: "en",
                value: "open issues Webpage"
            },
            {
                lang: "fr",
                value: "Page Web des problèmes en suspens"
            },
            {
                lang: "it",
                value: "Aprire la pagina Web dei problemi"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "MyCommission",
        path: "mycommission",
        component: MyCommissionPageComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: myComisionColor,
        menuLabel: "MENU.ENTRIES.COMMISSION",
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Provisionen"
                },
                {
                    lang: "en",
                    value: "Commission"
                },
                {
                    lang: "fr",
                    value: "Commission"
                },
                {
                    lang: "it",
                    value: "Commissione"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Provisionen Webseite"
            },
            {
                lang: "en",
                value: "Commission Webpage"
            },
            {
                lang: "fr",
                value: "Page Web de la Commission"
            },
            {
                lang: "it",
                value: "Pagina web della Commissione"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Info",
        path: "info",
        component: InfoComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: infoColor,
        menuLabel: "Info",
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Info"
                },
                {
                    lang: "en",
                    value: "Info"
                },
                {
                    lang: "fr",
                    value: "Info"
                },
                {
                    lang: "it",
                    value: "Info"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Info"
            },
            {
                lang: "en",
                value: "Info"
            },
            {
                lang: "fr",
                value: "Info"
            },
            {
                lang: "it",
                value: "Info"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },

    {
        name: "Referrals",
        path: "Referrals",
        component: ReferralsComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: referralColor,
        menuLabel: "MENU.ENTRIES.REFERRALS",
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Weiterempfehlung"
                },
                {
                    lang: "en",
                    value: "Referrals"
                },
                {
                    lang: "fr",
                    value: "Recommandation"
                },
                {
                    lang: "it",
                    value: "Raccomandazione"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Referrals"
            },
            {
                lang: "en",
                value: "Referrals"
            },
            {
                lang: "fr",
                value: "Referrals"
            },
            {
                lang: "it",
                value: "Referrals"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Lead Management",
        path: "lead-management",
        component: LeadsManagementComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: false,
        color: leadManagementColor,
        menuLabel: "MENU.ENTRIES.LEADMANAGEMENT",
        canActivate: [AuthGuard, ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Lead Management"
                },
                {
                    lang: "en",
                    value: "Lead Management"
                },
                {
                    lang: "fr",
                    value: "Gestion des leads"
                },
                {
                    lang: "it",
                    value: "Gestione del piombo"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Lead Management"
            },
            {
                lang: "en",
                value: "Lead Management"
            },
            {
                lang: "fr",
                value: "Lead Management"
            },
            {
                lang: "it",
                value: "Lead Management"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Termin Management",
        path: "termin-management",
        component: TerminManagementComponent,
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: false,
        color: terminManagementColor,
        menuLabel: "MENU.ENTRIES.TERMINMANAGEMENT",
        canActivate: [AuthGuard, ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Termin Management"
                },
                {
                    lang: "en",
                    value: "Appointment Management"
                },
                {
                    lang: "fr",
                    value: "Gestion des rendez-vous"
                },
                {
                    lang: "it",
                    value: "Gestione appuntamenti"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Termin Management"
            },
            {
                lang: "en",
                value: "Appointment Management"
            },
            {
                lang: "fr",
                value: "Gestion des rendez-vous"
            },
            {
                lang: "it",
                value: "Gestione appuntamenti"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Events",
        path: "events",
        component: EventsComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: eventsColor,
        menuLabel: "MENU.ENTRIES.EVENTS",
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Events"
                },
                {
                    lang: "en",
                    value: "Events"
                },
                {
                    lang: "fr",
                    value: "Events"
                },
                {
                    lang: "it",
                    value: "Events"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Events"
            },
            {
                lang: "en",
                value: "Events"
            },
            {
                lang: "fr",
                value: "Events"
            },
            {
                lang: "it",
                value: "Events"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Leadpool",
        path: "leadpool",
        component: LeadPoolComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: terminManagementColor,
        menuLabel: "MENU.ENTRIES.LEAD-POOL",
        canActivate: [LeadpoolGuard,ClientGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Leadpool"
                },
                {
                    lang: "en",
                    value: "Leadpool"
                },
                {
                    lang: "fr",
                    value: "Leadpool"
                },
                {
                    lang: "it",
                    value: "Leadpool"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Leadpool"
            },
            {
                lang: "en",
                value: "Leadpool"
            },
            {
                lang: "fr",
                value: "Leadpool"
            },
            {
                lang: "it",
                value: "Leadpool"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "cancellation-management",
        path: "cancellation-management",
        component: CancellationManagementComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: terminManagementColor,
        menuLabel: "MENU.ENTRIES.CANCELLATION_MANAGEMENT",
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Kündigungsmanagement"
                },
                {
                    lang: "en",
                    value: "Cancellation Management"
                },
                {
                    lang: "fr",
                    value: "Ggestion de la résiliation"
                },
                {
                    lang: "it",
                    value: "Gestione delle cancellazioni"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Kündigungsmanagement"
            },
            {
                lang: "en",
                value: "Cancellation Management"
            },
            {
                lang: "fr",
                value: "Ggestion de la résiliation"
            },
            {
                lang: "it",
                value: "Gestione delle cancellazioni"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "mortgage",
        path: "mortgage",
        component: MortgageComponent,
        isInMenu: true,
        isActive: false,
        hasBreadcrumb: false,
        color: mortgageColor,
        menuLabel: "MENU.ENTRIES.MORTGAGE",
        canActivate: [ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Hypothek"
                },
                {
                    lang: "en",
                    value: "Mortgage"
                },
                {
                    lang: "fr",
                    value: "Hypothèque"
                },
                {
                    lang: "it",
                    value: "Mutuo"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Hypothek"
            },
            {
                lang: "en",
                value: "Mortgage"
            },
            {
                lang: "fr",
                value: "Hypothèque"
            },
            {
                lang: "it",
                value: "Mutuo"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Livechat",
        path: "livechat",
        component: AgentPageComponent,
        loadChildren: () => import('./modules/livechat/support/livechat-routing.module').then(m => m.LivechatRoutingModule),
        isInMenu: false,
        isActive: false,
        hasBreadcrumb: false,
        color: referralColor,
        menuLabel: "MENU.ENTRIES.LIVECHAT",
        canActivate: [LiveChatGuard, AdminGuard, ClientGuard, SupplierGuard],
        title:
            [
                {
                    lang: "de",
                    value: "Livechat"
                },
                {
                    lang: "en",
                    value: "Livechat"
                },
                {
                    lang: "fr",
                    value: "Livechat"
                },
                {
                    lang: "it",
                    value: "Livechat"
                }
            ],
        description: [
            {
                lang: "de",
                value: "Livechat"
            },
            {
                lang: "en",
                value: "Livechat"
            },
            {
                lang: "fr",
                value: "Livechat"
            },
            {
                lang: "it",
                value: "Livechat"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    },
    {
        name: "Test",
        path: "test",
        component: TestPageComponent,
        isInMenu: DevelopModeGuard.prototype.canActivate(),
        isActive: false,
        hasBreadcrumb: false,
        menuLabel: "MENU.ENTRIES.TEST",
        title: [
            {
                lang: "de",
                value: "neo! - Test"
            },
            {
                lang: "en",
                value: "neo! - Testen"
            },
            {
                lang: "fr",
                value: "neo! - Testfr"
            },
            {
                lang: "it",
                value: "neo! - Testit"
            }
        ],
        description: [
            {
                lang: "de",
                value: "Test"
            },
            {
                lang: "en",
                value: "Test_en"
            },
            {
                lang: "fr",
                value: "Test_fr"
            },
            {
                lang: "it",
                value: "Test_it"
            }
        ],
        routerParameter: null,
        routerAnchors: null
    }
];
//NOSONAR
