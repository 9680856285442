import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from "@angular/router";
import { ROUTES, ROUTING_DATA } from "./app-routing.constants";
import { environment } from "../environments/environment";
import { Meta, Title } from "@angular/platform-browser";
import { log } from "./modules/core/providers/logger.provider";
import { ConfigProvider } from "./modules/core/providers/config.provider";
import { RegisterProvider } from "./modules/core/providers/register.provider";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MediaMatcher } from "@angular/cdk/layout";
import { VersionProvider } from "./modules/core/providers/version.provider";
import { LoadingService } from "./modules/core/providers/loading.component";
import { HttpCancelService } from "./modules/core/providers/HttpCancelService";
import { ContextSearchService } from "./modules/core/services/context-search.service";
import { KeycloakService } from "keycloak-angular";
import { Person } from "./modules/core/model/person";
import { AgenturType } from "./modules/core/model/enum-agenturtype";
import { LeadsService } from "./modules/leads/services/leads.service";
import { filter, map, mergeMap } from "rxjs/operators";
import { StrapiService } from "./modules/shared/services/strapi.service";
import { LeadProvider } from "./modules/core/providers/lead.provider";
import { AppointmentProvider } from "./modules/core/providers/appointment.provider";
import { ClientGuard } from "./modules/core/guards/ClientGuard";
import { SupplierGuard } from "./modules/core/guards/SupplierGuard";
import { LivechatService } from "./modules/livechat/support/livechat.service";
import { Subscription } from "rxjs";
import { AdvisorService } from "./modules/core/providers/advisor.service";
import { AdminGuard } from "./modules/core/guards/AdminGuard";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild("sidenav")
    sidenav: MatSidenav;
    @Input()
    color: string;


    public TAG: string = this.constructor.name;
    public version = "not yet loaded";
    public contentPageTitel;
    public hasBreadcrumb;
    events: string[] = [];
    openedSideNav = true;
    routingData = ROUTING_DATA;
    public mobileQuery: MediaQueryList;
    readonly _mobileQueryListener: () => void;
    isLoading = false;
    _selected = false;
    agents: Person[] = [];
    agenturType: string = "";
    agenturName: string = "";
    advisorId: string = "";
    liveChatAgent: string = "";
    showAlert: string = ''
    animationText: boolean;
    bgColor: string = '';
    fontColor: string = '';
    menuItems = [];
    isAgentBlocked: boolean;

    rooms$!: any;
    listenerSubscriptions: Subscription[] = [];


    constructor(public router: Router,
        public configDataProvider: ConfigProvider,
        public registerProvider: RegisterProvider,
        public versionProvider: VersionProvider,
        public titleService: Title,
        public meta: Meta,
        public translate: TranslateService,
        public activatedRoute: ActivatedRoute,
        public changeDetectorRef: ChangeDetectorRef,
        public media: MediaMatcher,
        public loadService: LoadingService,
        public httpCancelService: HttpCancelService,
        public searchService: ContextSearchService,
        public leadService: LeadsService,
        private keycloak: KeycloakService,
        private strapiService: StrapiService,
        public leadProvider: LeadProvider,
        public appointmentProvider: AppointmentProvider,
        public livechatService: LivechatService,
        public advisorService: AdvisorService
    ) {
        this.mobileQuery = media.matchMedia("(max-width: 959px)");
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        this.keycloak.loadUserProfile().then((res: any) => {
            this.agenturType = res.attributes.agenturType[0];
            this.agenturName = res.attributes.agenturName[0];
            this.advisorId = res.attributes.advisorid[0];
            this.liveChatAgent = res.attributes.livechatAgent ? res.attributes.livechatAgent[0] : false;

            if (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead) {
              this.leadProvider.getUnassignedLeads(undefined, 0, 1000)
                .subscribe((leads: { data: Person[]; totalCount: number }) => {                
                this.leadService.unassignedLeadsCount.next(leads.totalCount);
                })

                this.appointmentProvider
                .getUnassignedAppointments(undefined, false, 0, 1000)
                .subscribe((leads: { data: Person[]; totalCount: number }) => {
                    this.leadService.unassignedAppointmentsCount.next(leads.totalCount); 
                });
                this.leadProvider.getUnassignedLeads(undefined, 0, 1000, "leadpool=true") 
                .subscribe((leads: { data: Person[]; totalCount: number }) => {    
                  this.leadService.unassignedLeadPoolCount.next(leads.totalCount);   
                })

            }

            this.getMenuItems();
            if (this.liveChatAgent === 'true') {
                this.livechatService.rooms$.subscribe(rooms => {
                    if(rooms !== null){
                        let onlineRooms = rooms!.filter(room => room.status === true);
                        this.livechatService.onlineRooms$.next(onlineRooms.length);
                        this.livechatService.newMessages$.next(onlineRooms.map<number>(x => x.newMessages).reduce((partialSum, a) => partialSum + a, 0));
                    }
                })
    
                this.livechatService.livechatInit(this.listenerSubscriptions);
            }
        });
        log.setLogLevel(environment.settings.LOG_LEVEL);
        log.debug(`${this.TAG} OnInit`);
        this.routingData.forEach((route) => {
            this.addComponent(route.name, route.path, route.component, route.title, route.description,
                route.routerParameter, route.routerAnchors, route.hasBreadcrumb, route.canDeactivate || [], route.canActivate || [], ...(route.loadChildren ? [route.loadChildren] : []));
        });
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === "primary"),
                mergeMap(route => route.data))
            .subscribe((event) => {
                const lang = this.translate.currentLang;
                const allDescriptions = event["description"];
                const allTitles = event["title"];
                this.hasBreadcrumb = event["hasBreadcrumb"];
                this.setSiteInformations(lang, allTitles, allDescriptions);
                if (Object.keys(event).length > 0){
                    this.setActiveMenu(event["path"]);
                }
            });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.activatedRoute.firstChild.data.subscribe((event) => {
                const lang = this.translate.currentLang;
                const allDescriptions = event["description"];
                const allTitles = event["title"];
                this.setSiteInformations(lang, allTitles, allDescriptions);
            });
        });

        // start google analytics
        if (environment.settings.USE_GOOGLE_ANALYTICS) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    log.debug(`${this.TAG} page title: ${this.titleService.getTitle()}`);
                    (window as any).gtag("config", "UA-125267780-1", {
                        "page_title": this.titleService.getTitle(),
                        "page_path": event.urlAfterRedirects
                    });
                }
            });
        }

        // apply request cancelling on route changes
        this.router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                this.httpCancelService.cancelPendingRequests();
            }
        });

        // this.versionProvider.getVersion().subscribe(data => {
        //     this.version = data.Version;
        // });
        this.loadService.status.subscribe(async (val: boolean) => {
            this.isLoading = await val;
        });
        this.getScheduledMaintenance();

        
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    getScheduledMaintenance() {
        this.strapiService.getMaintenance().subscribe(x => {
            const appName = "Makler-ui";
            const maintenanceData = x.data.find(x => x.websiteName === appName)
            this.animationText = maintenanceData.animationText;
            if (maintenanceData.visible === true) {
                this.showAlert = maintenanceData.displayText;
            }
            this.bgColor = maintenanceData.bgColor;
            this.fontColor = maintenanceData.fontColor;
            switch (this.fontColor) {
                case 'white':
                    this.fontColor = 'neo-white';
                    break;
                case 'orange':
                    this.fontColor = 'neo-orange';
                    break;
                case 'neoColor':
                    this.fontColor = 'neo-neoColor';
                    break;
                default:
                    break;
            }
        })
    }
    addComponent(name: string, path: string, component: any, title: { lang: string, value: string }[],
        descr: { lang: string, value: string }[],
        routerParameter: string[] = null, routerAnchors: string[] = null, hasBreadcrumb: boolean = false, canDeactivate?: any[], canActivate?: any[], loadChildren?: any) {
        log.debug(`${this.TAG} adding component: ${name} with path: ${path} Parameter: ${routerParameter} Anchors: ${routerAnchors}`);
        this.registerProvider.register(name, path, component, routerParameter, routerAnchors, {
            title,
            description: descr, loadChildren: loadChildren
        });
        ROUTES.push({ path, component, data: { title, description: descr, path, hasBreadcrumb }, canDeactivate, canActivate, 
            ...(loadChildren ? {loadChildren} : [])
        });
        if (routerParameter !== null && routerParameter.length > 0) {
            let pathinklParam = `${path}`;
            for (const item of routerParameter) {
                pathinklParam += `/:${item}`;
                ROUTES.push({ path: pathinklParam, component, data: { title, description: descr, path, hasBreadcrumb }, canDeactivate, canActivate });
            }
        }
        if (routerAnchors !== null && routerAnchors.length > 0) {
            for (const item of routerAnchors) {
                const pathWAnchor = `${path}#${item}`;
                ROUTES.push({ path: pathWAnchor, component, data: { title, description: descr, path, hasBreadcrumb } });
            }
        }
        this.router.resetConfig(ROUTES);
    }

    updateMetaInfo(description, author) {
        this.meta.updateTag({ name: "description", content: description });
        this.meta.updateTag({ name: "author", content: author });
    }

    
   async getMenuItems() {
        const advisorData = await this.advisorService.getAdvisorData().then((response) =>{
            this.isAgentBlocked = response.blocked;
            if (this.isAgentBlocked) {
                const leadpoolItem = this.routingData.find(item => item.path === 'leadpool');
            }
    
        })

        
        if (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead) {
            this.routingData.find((item) => item.path == 'lead-management').isInMenu = true;
            this.routingData.find((item) => item.path == 'termin-management').isInMenu = true;
        } else if (this.agenturType === AgenturType.Client) {
           let clientProtectedRoutes =  this.routingData.filter((item) => item.canActivate?.some(guard => guard == ClientGuard));
           clientProtectedRoutes.forEach(x => x.isInMenu = false);

           let myCustomersDetailPage = this.routingData.find((item) => item.name == "MyData");
           let myDataPolicyDetail = this.routingData.find((item) => item.name == "MyDataPolicyDetail");
           
            this.registerProvider.setPath("MyCustomersDetail", myCustomersDetailPage.path);
            this.registerProvider.setPath("MyCustomersPolicyDetail", myDataPolicyDetail.path);

           myCustomersDetailPage.path =  `${this.registerProvider.getPath("MyData")
            }/${this.advisorId}`;

           myCustomersDetailPage.isInMenu = true;
        } else if (this.agenturType === AgenturType.Supplier) {
            let supplierProtectedRoutes =  this.routingData.filter((item) => item.canActivate?.some(guard => guard == SupplierGuard));
           supplierProtectedRoutes.forEach(x => x.isInMenu = false);           
        } else if (this.agenturType === AgenturType.Admin) {
            let adminProtectedRoutes =  this.routingData.filter((item) => item.canActivate?.some(guard => guard == AdminGuard));
            this.routingData.find((item) => item.path == 'neo-offers').isInMenu = true;
            adminProtectedRoutes.forEach(x => x.isInMenu = true);
            if (this.liveChatAgent !== 'true') {
                this.routingData.find((item) => item.path == 'livechat').isInMenu = false;
                this.routingData.find((item) => item.path == 'livechat').isActive = false;
            }
        }
        this.menuItems = this.routingData.filter((item) => item.isInMenu);
    }

    setActiveMenu(menuPath) {

        let path = menuPath;
        const pathSplit = menuPath.split("/");
        if (pathSplit && pathSplit.length > 0) {
            path = pathSplit[0];
        }

        this.routingData
            .filter((item) => item.isActive)
            .forEach((item) => {
                item.isActive = false;
            });

        const item = this.routingData.find((item) => item.isInMenu && item.path === path);
        if (item !== undefined) {
            item.isActive = true;
            // it's required to reset the search over the menu too.
            if (`${this.registerProvider.getPath("SearchResult")}` !== path) {
                this.searchService.reset();
            }
        }
    }

    private setSiteInformations(lang: string, allTitles: any, allDescriptions: any) {
        if (allTitles) {
            allTitles.forEach((item) => {
                if (item.lang === lang) {
                    this.titleService.setTitle(item.value);
                    this.contentPageTitel = item.value;
                }
            });
        }
        if (allDescriptions) {
            allDescriptions.forEach((item) => {
                if (item.lang === lang) {
                    this.updateMetaInfo(item.value, "Neosana AG");
                }
            });
        }
    }

    toggleNavigation() {
        this.sidenav.toggle();
    }

    getActiveItemInMenu() {
        const activeRoute = this.routingData.filter((item) => item.isActive);
        if (activeRoute.length > 0) {
            return activeRoute[0].isInMenu;
        }
        return false;
    }
    CloseSidenavOnMobile() {
        if (this.mobileQuery.matches) {
            this.sidenav.toggle();
        }
    }



    // livechatInit() {
    //     this.keycloak.loadUserProfile().then((res: any) => {
    //       this.livechatService
    //         .getRole(res.attributes.livechatRole[0])
    //         .subscribe((payout: any) => {
    //           let role = payout.role;
    //           let agent: IAgent = {
    //             name: res.firstName,
    //             lastName: res.lastName,
    //             email: res.email,
    //             id: res.attributes.advisorid[0],
    //             categoryId: res.attributes.categoryId[0],
    //             role: role,
    //           };
    //           this.router.navigate(['/livechat/chats']);
    //           this.livechatService
    //             .registerAgent(agent, res)
    //             .subscribe((payout) => {});
    //           this.livechatService.agent$.next(agent);
    //           console.log(this.agent, 'first');
    //           if(this.agent) this.livechatService.getAgentRooms(this.agent);
    //           if(!this.livechatService.connected$.value) {
    //             this.livechatService.connectSocket();
    //             this.livechatService.emit('agent-logged-on', {
    //               agentId: res.attributes.advisorid[0],
    //             });
    //           }
    //         });
    //     });
    
    //     this.listenerSubscriptions.forEach((s) => s.unsubscribe());
    //     this.livechatService.agent$.subscribe((value) => { this.agent = value; });
    //     console.log(this.agent, 'second');
    
    //     if(this.agent) this.livechatService.getAgentRooms(this.agent);
    
    //     this.livechatService.rooms$.subscribe((value) => {
    //       if (value !== null) {
    //         let roomIds = [];
    //         for (let room of value) {
    //           roomIds.push(room.roomId ? room.roomId : room._id);
    //         }
    //         this.livechatService.join(roomIds);
    //       }
    //     });
    
    //     this.livechatService.connected$.subscribe((valid) => {
    //       if (valid) {
    //         if (!this.livechatService.hasListener('room-created')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('room-created')
    //               .subscribe((payout: { room: IRoom }) => {
    //                 console.log('room')
    //                 if (
    //                   payout.room.agentId === this.agent?.id ||
    //                   this.agent?.role.globalPerms
    //                 ) { this.livechatService.addNewRoom(payout.room); }
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('client-reinited-chat')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('client-reinited-chat')
    //               .subscribe((payout: { room: IRoom }) => {
    //                 this.livechatService.reinitChat(payout.room);
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('message-2nd')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('message-2nd')
    //               .subscribe((payout: { message: IMessage; roomId: string }) => {
    //                 this.livechatService.emit('deliver', {
    //                   message: payout.message,
    //                   roomId: payout.roomId,
    //                 });
    //                 this.livechatService.newMessage(payout.message, payout.roomId);
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('delivered')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('delivered')
    //               .subscribe((payout: { roomId: string }) => {
    //                 this.livechatService.changeMessageStatus(
    //                   'delivered',
    //                   payout.roomId
    //                 );
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('read')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('read')
    //               .subscribe((payout: { roomId: string }) => {
    //                 this.livechatService.changeMessageStatus('read', payout.roomId);
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('room-updated')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('room-updated')
    //               .subscribe((payout: { room: IRoom }) => {
    //                 this.livechatService.clientStatusUpdate(payout.room);
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('room-status-updated')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('room-status-updated')
    //               .subscribe((payout: { room: IRoom }) => {
    //                 this.livechatService.archiveChat(payout.room);
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('room-archived')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('room-archived')
    //               .subscribe((payout: { room: IRoom }) => {
    //                 this.livechatService.archiveChat(payout.room);
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('ticket-assigned')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('ticket-assigned')
    //               .subscribe((payout: any) => {
    //                 if (this.agent && this.agent?.id === payout.agentId) {
    //                   this.livechatService.getAgentRooms(this.agent);
    //                 }
    //               })
    //           );
    //         }
    
    //         if (!this.livechatService.hasListener('ticket-created')) {
    //           this.listenerSubscriptions.push(
    //             this.livechatService
    //               .listen('ticket-created')
    //               .subscribe((payout: any) => {
    //                 if (this.agent && this.agent?.id === payout.agentId) {
    //                   this.livechatService.getAgentRooms(this.agent);
    //                 }
    //               })
    //           );
    //         }
    //       } else {
    //         this.listenerSubscriptions.forEach((s: Subscription) => { s.unsubscribe(); });
    //       }
    //     });
    // }


}