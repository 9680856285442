import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { compressToEncodedURIComponent } from "lz-string";

@Component({
  selector: 'neomp-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnInit {
  advisorId: string;
  agencyId: string;
  compressedData: string;
  showWigaEvent: boolean = false;
  showSwissIndoorsEvent: boolean = true;
  showNoraEvent: boolean = true;
  constructor(
    public keycloak: KeycloakService,
  ) { }

  ngOnInit() {
    this.getAdvisorUid();
  }

  goToEvent(year: number) {

    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    const pathByYear = year === 2022 ? 'olma' : 'olma-2023';

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/${pathByYear}?query=${this.compressedData}`);
  }

  goToAMAEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "ama?query=" + this.compressedData);
  }

  goToEmPublicViewings() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "em-public-viewing?query=" + this.compressedData);
  }

  goToGlarnerMesseEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }
    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/glarner-messe-2023?query=${this.compressedData}`);
  }

  goToHigaEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "higa?query=" + this.compressedData);
  }
  goToBeaEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "bea?query=" + this.compressedData);
  }
  goToOffaEvent(year: number) {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    const pathByYear = year === 2024 ? 'offa-2024' : 'offa';

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/${pathByYear}?query=${this.compressedData}`);
  }


  goToWigaEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "wiga?query=" + this.compressedData);
  }

  goToSigaEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "siga?query=" + this.compressedData);
  }

  compressData(data: any): string {
    return compressToEncodedURIComponent(JSON.stringify(data));
  }

  getAdvisorUid() {
    this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorId = res.attributes.advisorid[0];
      this.agencyId = res.attributes.agenturID[0];
      this.showWigaEvent = ['835900', '396099', '303264'].includes(this.agencyId);
      this.showNoraEvent = ['303264'].includes(this.agencyId) && ['494895'].includes(this.advisorId);
    });
  }

  goToSwissIndoorsEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "swiss-indoors?query=" + this.compressedData);
  }

  goToNoraEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "nora?query=" + this.compressedData);
  }

  goToRHEMAEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "rhema?query=" + this.compressedData);
  }

  goToBauenAndWohnenEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "bauen-wohnen?query=" + this.compressedData);
  }


  goToTriathlonDeLausanneEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "triathlon-de-lausanne?query=" + this.compressedData);
  }

  goToFiba3x3Event() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "fiba3x3?query=" + this.compressedData);
  }

  goToMusicFestivalPromoEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "music-festival-promo?query=" + this.compressedData);
  }

  goToBauenAndModernisierenEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "bauen-modernisieren?query=" + this.compressedData);
  }

  goToZOMEvent() {
    const queryParams = {
      advId: this.advisorId,
      agencyId: this.agencyId
    }

    this.compressedData = this.compressData(queryParams);
    window.open(`${environment.settings.REGISTER_MYNEO}/` + "zom?query=" + this.compressedData);
  }

}
