import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {

  private http: HttpClient;
  private API_URL = environment.settings.PORTAL_CMS_URL;

  constructor( handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }
 
  //GET
  getMaintenance(): Observable<any> {
    let queryParams = { "populate": "*" };
    return this.http.get(this.API_URL + '/api/maintenance-components', { params: queryParams });
  }

  getAppointmentToolCms() {
    return this.http.get(`${environment.settings.PORTAL_CMS_URL}/api/appointment-tool`, {
      headers: {authorization: `Bearer ${environment.settings.PORTAL_API_TOKEN}`}, params: { populate: '*'}
    })
  }
}
