<div class="mdc-layout-grid msg-helper-padding-none leads-edit">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <form [formGroup]="leadForm">
        <div class="mdc-layout-grid__inner">
          <div
            class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
            <section class="neo-color neo-padding-top-10">
              <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                <div class="mdc-layout-grid__inner">
                  <!-- TODO: class for inline style -->
                  <div
                    class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-4-tablet ">
                    <mat-form-field class="neo-leads-input-full-width">
                      <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.ID' | translate}} </mat-label>
                      <input class="font-family" matInput type="text" readonly formControlName="termin_id" />
                    </mat-form-field>
                  </div>

                  <!-- Status -->
                  <div
                    class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-4-tablet">
                    <mat-form-field class="neo-leads-input-full-width">
                      <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.STATUS' | translate}}</mat-label>
                      <input class="font-family" matInput type="text" readonly
                        formControlName="{{ leadView ? 'lead_status' : 'termin_status'}}" />
                    </mat-form-field>

                  </div>
                  <!-- Substatus -->
                  <div
                    class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-4-tablet">
                    <mat-form-field class="neo-leads-input-full-width">
                      <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.SUBSTATUS' | translate}}</mat-label>
                      <input class="font-family" matInput type="text" readonly
                        formControlName="{{ leadView ? 'lead_substatus' : 'termin_substatus'}}" />
                    </mat-form-field>

                  </div>
                  <div
                    *ngIf="!isSupplier && canEdit"
                    class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet neo-padding-top-5">
                    <button color="primary" appearance="fill" class="neo-leads-input-full-width" mat-raised-button
                      (click)="setStatusDialog()" [disabled]="isDialogOpen">
                      <span *ngIf="leadView"> {{ 'PAGE.LEADS.LEAD.LEAD-EDIT.SAVE-STATUS' | translate}} </span>
                      <span *ngIf="!leadView"> {{ 'PAGE.LEADS.LEAD.TERMINABLATT.SAVE-STATUS' | translate}} </span>

                    </button>
                  </div>
                  <div
                    *ngIf="!isSupplier && canEdit"
                    class="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-2-tablet neo-padding-top-5">
                    <button class="gm-offer-button" mat-raised-button [disabled]="agentNumber === '0'"
                      (click)="openGmOfferDialog()">{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.GMOFFER' | translate }}</button>
                  </div>
                  <div
                    *ngIf="!isSupplier && canEdit"
                    class="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-phone mdc-layout-grid__cell--span-4-tablet neo-padding-top-5 gap">
                      <button color="primary" (click)="goToPreviousLead()" mat-raised-button [disabled]="disabledToGoPrevious">˂ {{ 'PAGE.CUSTOMERDETAIL.ACTIONS.PREVIOUS' | translate }}</button>
                      <button color="primary" (click)="goToNextLead()" mat-raised-button [disabled]="disabledToGoNext">{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.NEXT' | translate }} ˃</button>
                  </div>


                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-2-phone"></div>
        <br />
      </form>
      <div class="mdc-layout-grid no-padding ">
        <div class="mdc-layout-grid__inner no-padding">
          <div
            class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone no-padding">
            <form [formGroup]="leadForm">
              <neomp-container  [showActionIcon]="!isSupplier && canEdit" [editSection]="'PersonalData'" (edit)="edit($event)" (cancel)="cancel($event)"
                cssClass="msg-mp-container-frameless " headlineText="{{ 'PAGE.LEADS.TERMIN.PERSONALIE' | translate }}">
                <div
                  class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
                  <section>
                    <div class="mdc-layout-grid__inner">
                      <div
                        class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet gap-column">

                      <!-- Gender -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ "PAGE.CUSTOMERDETAIL.CONTACT.GENDER" | translate }}</mat-label>
                          <mat-select formControlName="gender" (selectionChange)="updateValue('gender',$event.value)">
                            <mat-option *ngFor="let gender of genderData" [value]="gender">
                              {{ gender }}
                            </mat-option>
                          </mat-select>
                          <mat-error  *ngIf="leadForm.controls['gender'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>                            
                        </mat-form-field>

                      <!-- Language -->
                        <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.LANGUAGE' | translate}}</mat-label>
                          <mat-select formControlName="preferedLanguage" (selectionChange)="updateValue('preferedLanguage',$event.value)">
                            <mat-option *ngFor="let lang of leadConfigs.LANGUAGE" [value]="lang.value">
                              {{ lang.value }}
                            </mat-option>
                          </mat-select>
                            <mat-error  *ngIf="leadForm.controls['preferedLanguage'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>                            
                        </mat-form-field>
                        
                      <!-- NAME -->
                      <mat-form-field class="neo-leads-input-full-width">
                        <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.NAME' | translate}}</mat-label>
                        <input class="font-family" matInput type="text" formControlName="firstName" (input)="updateValue('firstName',$event.target.value)"/>
                        <mat-error  *ngIf="leadForm.controls['firstName'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>              
                      </mat-form-field>

                      <!-- SURNAME -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.SURNAME' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="name" (input)="updateValue('name',$event.target.value)"/>
                          <mat-error  *ngIf="leadForm.controls['name'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>   
                        </mat-form-field>

                      <!-- BIRTHDATE -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.BIRTHDATE' | translate}}</mat-label>
                          <input [matDatepicker]="birthdate" matInput formControlName="birthday" (dateChange)="updateValue('birthday',$event.target.value)" />
                          <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
                          <mat-datepicker #birthdate></mat-datepicker>
                          <mat-error  *ngIf="leadForm.controls['birthday'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>   
                        </mat-form-field>
                      
                      <!-- STREET -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.STR' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="address" (input)="updateValue('address',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['address'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>   
                        </mat-form-field>

                      <!-- POSTAL CODE -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.ZIP' | translate}}</mat-label>
                          <input class="font-family" matInput type="text" formControlName="zip" (input)="updateValue('zip',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['zip'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>   
                        </mat-form-field>

                      <!-- ORT -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.CITY' | translate}}</mat-label>
                          <input class="font-family" matInput type="text" formControlName="ort" (input)="updateValue('ort',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['ort'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>   
                        </mat-form-field>

                      </div>
                      
                      <div
                        class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet gap-column">
                        
                        <!-- TELEFON Private -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.PHONE-PRIVATE' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="phone2" (input)="updateValue('phone2',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['phone2'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                        </mat-form-field>

                        <!-- EMAIL Private -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.EMAIL-PRIVATE' | translate}}</mat-label>
                          <input class="font-family" matInput type="text" formControlName="email2" (input)="updateValue('email2',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['email2'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                        </mat-form-field>

                         <!-- TELEFON Business -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.PHONE-BUSINESS' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="phone1" (input)="updateValue('phone1',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['phone1'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                        </mat-form-field>

                        <!-- EMAIL Business  -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.EMAIL-BUSINESS' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="email1" (input)="updateValue('email1',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['email1'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                        </mat-form-field>

                        <!-- Person -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.PERSON' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="lead_personen" (input)="updateValue('lead_personen',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['lead_personen'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                        </mat-form-field>

                        <!-- Source -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.LEAD-EDIT.SOURCE' | translate}} </mat-label>
                          <input class="font-family" matInput type="text" formControlName="lead_source" (input)="updateValue('lead_source',$event.target.value)" />
                          <mat-error  *ngIf="leadForm.controls['lead_source'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                        </mat-form-field>
                      
                        <!-- Comment -->
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label class="mat-label-color">{{'PAGE.LEADS.LEAD.LEAD-EDIT.COMMENT' | translate}}</mat-label>
                          <textarea class="font-family" matInput type="text" formControlName="lead_extradata"
                            rows="6" (input)="updateValue('lead_extradata',$event.target.value)"></textarea>
                            <mat-error  *ngIf="leadForm.controls['lead_extradata'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                         </mat-form-field>

                        </div>


                    </div>
                  </section>

                </div>
              </neomp-container>
            </form>
          </div>
          <div
            class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone no-padding">
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone no-padding">

                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 -desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone no-padding">

                    <neomp-container cssClass="msg-mp-container-frameless"
                      headlineText="{{ 'PAGE.LEADS.TERMIN.APPOINTMENT' | translate }}" (edit)="edit($event)" (cancel)="cancel($event)"
                      [showActionIcon]="!isSupplier && canEdit" [editSection]="'Appointment'">
                      <form [formGroup]="terminForm">

                        <div
                          class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-grid-gap-none msg-helper-margin-none msg-helper-padding-10 neo-space-padding">
                          <section>
                            <div class="mdc-layout-grid__inner">
                              <div
                                class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">

                                <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                                  <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.CALENDAR' | translate}}</mat-label>
                                  <mat-select formControlName="calendar_lang" readonly>
                                    <mat-option *ngFor="let lang of leadConfigs.LANGUAGE" [value]="lang.value">
                                      {{ lang.value }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>

                              </div>
                              <div
                                class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                                <mat-form-field class="neo-leads-input-full-width">
                                  <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.APPOINTMENT-DATE' | translate}}
                                  </mat-label>
                                  <input [matDatepicker]="termin_date" matInput formControlName="termin_date" />
                                  <mat-datepicker-toggle matSuffix [for]="termin_date"></mat-datepicker-toggle>
                                  <mat-datepicker #termin_date></mat-datepicker>
                                  <mat-error  *ngIf="terminForm.controls['termin_date'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>  
                                </mat-form-field>

                              </div>
                            </div>
                          </section>
                          <section>
                            <div class="mdc-layout-grid__inner">
                              <div
                                class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                                <mat-form-field class="neo-leads-input-full-width">
                                  <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.TIME' | translate}} </mat-label>
                                  <input class="font-family" matInput type="time" formControlName="termin_time" />
                                  <mat-error  *ngIf="terminForm.controls['termin_time'].hasError('required')">{{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>
                                </mat-form-field>
                              </div>
                              <div
                                class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                                <mat-form-field class="neo-leads-input-full-width">
                                  <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.VERMITTLER' | translate}} </mat-label>
                                  <input class="font-family" matInput type="text" formControlName="termin_agent" />
                                </mat-form-field>
                              </div>
                            </div>
                          </section>
                        </div>

                      </form>
                    </neomp-container>
                  </div>


                  <!-- Upload document section -->
                  <!-- <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
                    <div
                      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                      <neomp-container cssClass="msg-mp-container-frameless"
                        headlineText="{{ 'PAGE.OFFER.TITLE' | translate }}">
      
                        <div
                          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                          <neomp-upload-file [isNested]="true" [showHeadline]="false">
                          </neomp-upload-file>
                        </div>
      
                      </neomp-container>
                    </div>
                  </div> -->

                </div>
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">


                  <neomp-container cssClass="msg-mp-container-frameless"
                    headlineText="{{ 'PAGE.LEADS.TERMIN.FAMILYMEMBER' | translate }}" (edit)="edit($event)" (cancel)="cancel($event)"
                    [showActionIcon]="!isSupplier && canEdit" [editSection]="'FamilyTable'">
                    <table mat-table [dataSource]="familyMembers" multiTemplateDataRows matSort class="shadow">
                      <ng-container class="edit-termin-table-column-width" matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{
                          'PAGE.LEADS.LEAD.TERMINABLATT.NAME' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-form-field appearance="none">
                            <input type="text" matInput (change)="familyMembersContentChanged = true"
                              [(ngModel)]="element.name" placeholder="{{
                              'PAGE.LEADS.LEAD.TERMINABLATT.NAME' | translate
                            }}" required />
                          </mat-form-field>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{
                          'PAGE.LEADS.LEAD.TERMINABLATT.GENDER' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-form-field appearance="none">
                            <input type="text" matInput (change)="familyMembersContentChanged = true"
                              [(ngModel)]="element.gender" placeholder="{{
                              'PAGE.LEADS.LEAD.TERMINABLATT.GENDER' | translate 
                            }}" required />
                          </mat-form-field>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="birthdate">
                        <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{
                          'PAGE.LEADS.LEAD.TERMINABLATT.BIRTHDATE' | translate}}</th>
                        <td mat-cell *matCellDef="let element">


                          <mat-form-field appearance="none">
                            <input [matDatepicker]="fMemberbirthdate" (change)="familyMembersContentChanged = true"
                              matInput [(ngModel)]="element.birthdate" placeholder="{{
                            'PAGE.LEADS.LEAD.TERMINABLATT.BIRTHDATE' | translate  
                          }}" />
                            <mat-datepicker-toggle matSuffix [for]="fMemberbirthdate"></mat-datepicker-toggle>
                            <mat-datepicker #fMemberbirthdate></mat-datepicker>
                          </mat-form-field>

                        </td>
                      </ng-container>

                      <ng-container matColumnDef="nationality">
                        <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{
                          'PAGE.LEADS.LEAD.TERMINABLATT.NATIONALITY' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-form-field appearance="none">
                            <input type="text" (change)="familyMembersContentChanged = true" matInput
                              [(ngModel)]="element.nationality" placeholder="{{
                              'PAGE.LEADS.LEAD.TERMINABLATT.NATIONALITY' | translate 
                            }}" required />
                          </mat-form-field>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="column-w-20">
                          {{ "PAGE.LEADS.LEAD.TERMINABLATT.ACTION" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let person">
                          <button [disabled]="!editFamilyMemberMode" mat-raised-button color="warn"
                            (click)="deleteFamilyMember(person)">
                            {{ "PAGE.NEO-OFFER.OFFER.REMOVE-PERSON" | translate }}
                          </button>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </neomp-container>
                  <br/>

                  <div class="demo-button-container">
                    <div class="neo-margin-bottom">
                      <button [disabled]="!editFamilyMemberMode" (click)="addFamilyMember()" mat-raised-button
                        color="primary">
                        {{ 'PAGE.LEADS.LEAD.TERMINABLATT.ADD-DATA' | translate}}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <br />

      <div *ngIf="(!isSupplier && canEdit) && !leadView"
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone text-center">
        <div class="mdc-layout-grid__inner">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
              <neomp-container cssClass="msg-mp-container-frameless"
              headlineText="{{ 'MENU.LEADS-NAV.TASK' | translate  }}">
              <neomp-task [terminLeadId]="terminLeadId"
              > </neomp-task>
            </neomp-container>
          </div>
        </div>
      </div>
      <br />

      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone text-center">
        <div class="mdc-layout-grid__inner">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">

            <neomp-container cssClass="msg-mp-container-frameless"
              headlineText="{{'PAGE.LEADS.LEAD.LEAD-EDIT.REMINDER' | translate}}">
              <div
                class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                    <form [formGroup]="reminderForm">
                      <div class="mdc-layout-grid__cell--span-12">
                        <div class="mdc-layout-grid__inner">
                          <div
                            class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">

                            <mat-form-field class="neo-leads-input-full-width">
                              <!-- TODO translate -->
                              <mat-label>{{ 'Kategorie' | translate }}</mat-label>
                              <mat-select formControlName="category" [disabled]="isSupplier || !canEdit">
                                <mat-option *ngFor="let option of reminderCategories" [value]="option">
                                  {{ option.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                            *ngIf="displayReminderInputs.showDateInput">
                            <mat-form-field class="neo-leads-input-full-width">
                              <mat-label>{{ 'PAGE.LEADS.LEAD.TERMINABLATT.APPOINTMENT-DATE' | translate}}</mat-label>
                              <input [matDatepicker]="termindatum" matInput formControlName="date" [disabled]="isSupplier || !canEdit" />
                              <mat-datepicker-toggle matSuffix [for]="termindatum"></mat-datepicker-toggle>
                              <mat-datepicker #termindatum></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                            *ngIf="displayReminderInputs.showTimeInput">
                            <mat-form-field class="neo-leads-input-full-width" >
                              <mat-label>{{ 'PAGE.LEADS.LEAD.TERMINABLATT.TIME' | translate}} </mat-label>
                              <input class="font-family" matInput type="time" formControlName="time"  [readonly]="isSupplier || !canEdit"/>
                            </mat-form-field>
                          </div>
                          <div
                            class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                            <mat-form-field class="neo-leads-input-full-width">
                              <mat-label>{{ 'PAGE.LEADS.LEAD.TERMINABLATT.COMMENT' | translate}} </mat-label>
                              <input class="font-family" matInput type="text" formControlName="description"  [readonly]="isSupplier || !canEdit"/>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                    <div class="neo-padding-top-10">
                      <button [disabled]="reminderForm.invalid" class="neo-float-right" mat-raised-button
                        color="primary" (click)="addReminder()"  [disabled]="isSupplier || !canEdit">
                        + {{ 'PAGE.LEADS.LEAD.TERMINABLATT.REMINDER' | translate}}
                      </button>
                    </div>
                  </div>



                </div>
              </div>
            </neomp-container>
          </div>
        </div>
        <br />
      </div>
      <div
            class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10"
          >
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
              >
              <neomp-container cssClass="msg-mp-container-frameless"
              headlineText="{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TITLE' | translate }}">
              <neomp-leads-log 
              [tableEntries]="logs"
              ></neomp-leads-log>
              <neomp-loading-data #loadingLogsTable></neomp-loading-data>
              <neomp-paginator
              [length]="totalCount" 
              [pageSize]="pageSize" 
              [pageIndex]="pageIndex"
              [pageSizeOptions]="pageSizeOptions" 
              (paginatorChange)="paginatorValueChanged($event)"
              >  
              </neomp-paginator>  
              </neomp-container>
            </div>
            </div>
          </div>
        </div>

  </div>
</div>